@import '~swiper/swiper';

html {
	background: #000;
}

$theme-colors: (
	'primary': #13e780,
	'secondary': #11d073
);

$body-color: #000;
$body-bg: #fff;

$link-color: #13e780;
$link-hover-color: #11d073;

$font-family-sans-serif: 'Russo One', sans-serif;

$modal-content-bg: #000;

$btn-border-radius: 0;

@import '~bootstrap/scss/bootstrap';

.modal-fullscreen .modal-content > div {
	height: 100%;
}

:root {
	--bs-secondary-color: #fff;
}

.accordion {
	--bs-accordion-color: #fff;
}
